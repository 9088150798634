<template>
  <div>
    <div v-if="isDesigner" :style="textStyle">
      <pre>{{textValue?textValue:designerValue}}</pre>
    </div>
    <pre
      v-else
      :style="textStyle"
      @click="handleClick"
    >{{fvalue}}</pre>
  </div>
</template>

<script>
const dao = require('@/api/core/dao');
const utils = require('@/api/core/utils');
export default {
  name: 'yes-text',
  props: {
    formCreateInject: {
      type: Object,
      required: true,
    },
    dataSource: String,
    textValue: {
      type: String
    },
    textStyle: {
      type: String
    },
    formCreateName: String,
    clickEvent: String
  },
  data () {
    return {
      fvalue: "",
      designerValue: "",
      fDataSource: this.dataSource
    };
  },
  watch: {
    textValue (val) {
      this.fvalue = val;
      this.designerValue = val;
    },
    dataSource (val) {
      if (this.textValue && this.textValue != '') {
        return
      }
      this.designerValue = val;
    }
  },
  computed: {
    isDesigner () {
      return this.$parent.$options._componentTag === 'dragTool' || this.$parent.$parent.$parent.$options._componentTag === 'dragTool';
    }
  },
  updated () {
    // if (this.textValue) {
    //   this.fvalue = this.textValue;
    //   this.designerValue = this.textValue;
    // } else {
    //   this.fvalue = this.getValue();
    //   this.designerValue = this.getValue();
    // }
  },
  created () {
    // this.fvalue = this.textValue;
    if (this.textValue && this.textValue != '') {
      this.fvalue = this.textValue;
      this.designerValue = this.textValue;
    } else {
      this.fvalue = this.getValue();
      this.designerValue = this.getValue();
    }
  },
  methods: {
    getValue () {
      return this.fvalue;
    },
    setValue (val) {
      this.fvalue = val;
    },
    loadSourceData () {
      const req = utils.getSourceData(
        this.fDataSource
      );
      if (req) {
        this.fvalue = req;
      }
    },
    updateSourceData () {
      utils.updateSourceData(this.fDataSource, this.fvalue);
    },
    async handleClick () {
        const clickEvent = window.AsyncFunction("vm", "api", 'dao', 'utils', this.clickEvent);
        await clickEvent(this, this.formCreateInject.api, dao, utils)
    }
  },
}
</script>
