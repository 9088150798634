<template>
    <el-table
        ref="_yes_Table"
        :fit="fit"
        :lazy="lazy"
        :size="size"
        :load="loadFun"
        :stripe="stripe"
        :border="border"
        :data="tableData"
        :row-key="rowKey"
        :treeProps="treeProps"
        :empty-text="emptyText"
        :indent="currentIndent"
        :show-header="showHeader"
        :show-summary="showSummary"
        :default-sort="defaultSort"
        :span-method="spanFunction"
        :row-style="rowStyleFunction"
        :cell-style="cellStyleFunction"
        :tooltip-effect="tooltipEffect"
        :current-row-key="currentRowKey"
        :expand-row-keys="expandRowKeys"
        :filter-node-method="filterNode"
        :summary-method="summaryFunction"
        :height="fheight ? fheight : null"
        :default-expand-all="defaultExpandAll"
        :row-class-name="rowClassNameFunction"
        :cell-class-name="cellClassNameFunction"
        :header-row-style="headerRowStyleFunction"
        :header-cell-style="headerCellStyleFunction"
        :highlight-current-row="highlightCurrentRow"
        :max-height="fmaxHeight ? fmaxHeight : null"
        :select-on-indeterminate="selectOnIndeterminate"
        :header-row-class-name="headerRowClassNameFunction"
        :header-cell-class-name="headerCellClassNameFunction"
        @select="handleSelectionChange"
        @select-all="handleSelectionChange"
        @[event]="eventFlag ? rowDblclick($event) : rowClick($event)"
    >
        <slot />
    </el-table>
</template>

<script>
import arrayToTree from "array-to-tree";
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
const common = require("../utils/common");
export default {
    name: "yes-table",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        dataSource: String,
        viewDataSource: String,
        data: {
            //显示的数据
            type: Array,
            default: () => []
        },
        indexKey: {
            //索引匹配键
            type: String,
            default: ""
        },
        showCheckbox: {
            type: Boolean,
            default: false
        },
        multiSelect: {
            type: Boolean,
            default: true
        },
        height: {
            // Table 的高度，默认为自动高度。如果 height 为 number 类型，单位 px；如果 height 为 string 类型，则这个高度会设置为 Table 的 style.height 的值，Table 的高度会受控于外部样式。
            type: [String, Number]
        },
        maxHeight: {
            // Table 的最大高度。合法的值为数字或者单位为 px 的高度
            type: [String, Number]
        },
        stripe: {
            // 是否为斑马纹 table
            type: Boolean,
            default: false
        },
        border: {
            // 是否显示 table 的边框
            type: Boolean,
            default: false
        },
        size: {
            // Table 的尺寸，可选值为 medium / small / mini
            type: String
        },
        fit: {
            // 列的宽度是否自撑开
            type: Boolean,
            default: true
        },
        showHeader: {
            // 是否显示表头
            type: Boolean,
            default: true
        },
        highlightCurrentRow: {
            // 是否高亮当前行
            type: Boolean,
            default: false
        },
        currentRowKey: {
            // 当前行的 key
            type: [String, Number]
        },
        rowClassName: {
            // 行的 class 名称
            type: String,
            default: ""
        },
        rowStyle: {
            // 行的样式
            type: String,
            default: ""
        },
        cellClassName: {
            // 单元格的 class 名称
            type: String,
            default: ""
        },
        cellStyle: {
            // 单元格的样式
            type: String,
            default: ""
        },
        headerRowClassName: {
            // 表头行的 class 名称
            type: String,
            default: ""
        },
        headerRowStyle: {
            // 表头行的样式
            type: String,
            default: ""
        },
        headerCellClassName: {
            // 表头单元格的 class 名称
            type: String,
            default: ""
        },
        headerCellStyle: {
            // 表头单元格的样式
            type: String,
            default: ""
        },
        enableTree: {
            //启用树形表格
            type: Boolean,
            default: false
        },
        rowKey: {
            // 行 key 的参数
            type: String,
            default: ""
        },
        nodeId: {
            type: String,
            default: ""
        },
        parentId: {
            type: String,
            default: ""
        },
        emptyText: {
            // 空内容时显示的文本
            type: String,
            default: "暂无数据"
        },
        defaultExpandAll: {
            // 是否展开行
            type: Boolean,
            default: false
        },
        expandRowKeys: {
            // 展开行的 key，可以是一个数组或者单个 key
            type: Array
        },
        defaultSort: {
            // 默认排序
            type: Object,
            default: () => ({})
        },
        tooltipEffect: {
            // 单元格的 tooltip 效果 dark/light
            type: String
        },
        showSummary: {
            // 是否显示合计行
            type: Boolean,
            default: false
        },
        summaryMethod: {
            // 合计行的计算方法
            type: String,
            default: ""
        },
        spanMethod: {
            // 合并行或列的计算方法
            type: String,
            default: ""
        },
        indent: {
            // 展开行的缩进，以 px 为单位
            type: String,
            default: ""
        },
        lazy: {
            // 是否懒加载
            type: Boolean,
            default: false
        },
        loadEvent: {
            // 加载数据的方法
            type: String,
            default: ""
        },
        eventFlag: {
            type: Boolean,
            default: false
        },
        checkEvent: {
            // 选中事件
            type: String,
            default: ""
        },
        hasChildren: {
            type: String,
            default: ""
        },
        rowClickEvent: {
            // 行点击事件
            type: String,
            default: ""
        },
        rowDblclickEvent: {
            // 行双击事件
            type: String,
            default: ""
        },
        selectOnIndeterminate: {
            // 在多选表格中，当仅有部分行被选中时，点击表头的多选框时的行为。
            // 若为 true，则选中所有行；若为 false，则取消选择所有行
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fvalue: "",
            //标记为赋默认值
            tableData: [],
            clickValue: "",
            selectFlag: false,
            currentIndent: 16,
            orignTableData: [],
            fheight: this.height,
            checkedIndexValue: [],
            defaultCheckedFlag: false,
            fmaxHeight: this.maxHeight,
            fDataSource: this.dataSource,
            multiSelectValue: this.multiSelect,
            fViewDataSource: this.viewDataSource,
            treeProps: { hasChildren: "", children: "children" },
            event: this.eventFlag ? "row-dblclick" : "row-click",
            spanFunction: (param) => this.handleSpan(param, this.spanMethod),
            summaryFunction: (param) => this.handleSummary(param, this.summaryMethod),
            cellStyleFunction: (param) => this.handleCellStyle(param, this.cellStyle),
            rowStyleFunction: (param) => this.handleRowStyle(param, this.headerRowStyle),
            rowClassNameFunction: (param) => this.handleRowClassName(param, this.rowClassName),
            cellClassNameFunction: (param) => this.handleCellClassName(param, this.cellClassName),
            headerRowStyleFunction: (param) => this.handleHeaderRowStyle(param, this.headerRowStyle),
            headerCellStyleFunction: (param) => this.handleHeaderCellStyle(param, this.headerCellStyle),
            headerRowClassNameFunction: (param) => this.handleHeaderRowClassName(param, this.headerRowClassName),
            headerCellClassNameFunction: (param) => this.handleHeaderCellClassName(param, this.headerCellClassName)
        };
    },
    created() {
        this.currentIndent = this.indent ? parent(this.indent) : this.currentIndent;
        this.treeProps.hasChildren = this.hasChildren;
        if (this.$parent.$options._componentTag === "dragTool") {
            this.updatedSlotData();
            return;
        }
    },
    updated() {
        if (this.$parent.$options._componentTag !== "dragTool") {
            let tBody = this.$children[0].$children.find((item) => item.$vnode.componentOptions.tag === "table-body");
            //获取插槽元素
            const viewDataSource = this.fViewDataSource;
            tBody.$children.forEach((row) => {
                if (row.index >= 0) {
                    let children = row.$children ? row.$children : [];
                    if (children.length > 0) {
                        this.jointFDataSource(children, viewDataSource, row.index);
                    }
                }
            });
            // //执行插槽加载数据源方法
            tBody.$children.forEach((row) => {
                if (row.index >= 0) {
                    let children = row.$children ? row.$children : [];
                    if (children.length > 0) {
                        this.loadSlotSourceData(children);
                    }
                }
            });
            return;
        }
        if (this.$parent.$options._componentTag != "dragTool") {
            return;
        }
        if (this.$slots.default && this.$slots.default.length === this.tableData.length) {
            return;
        }
    },
    methods: {
        updateDoLayout() {
            this.$nextTick(() => {
                this.$refs._yes_Table.doLayout();
            });
        },
        setHeight(height) {
            this.fheight = height;
            this.fmaxHeight = height;
        },
        getHeight() {
            return this.fheight;
        },
        async execCol(row, col, func, data) {
            let tBody = this.$children[0].$children.find((item) => item.$vnode.componentOptions.tag === "table-body");
            let doc = tBody.$children[row].$children[col - 1].$children[0].$children[1];
            await doc[func](data);
        },
        // asyncFunction(param, method) {
        //   if (!method) return;
        //   const paramAsyncFunction = Object.getPrototypeOf(async function () { }).constructor;
        //   let paramEvent = paramAsyncFunction("param", "vm", "api", "utils", method);
        //   return paramEvent(param, this, this.formCreateInject.api, utils);
        // },
        handleSummary(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleSpan(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleHeaderRowStyle(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleHeaderRowClassName(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleHeaderCellClassName(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleHeaderCellStyle(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleRowClassName(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleRowStyle(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        handleCellClassName(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        clearAllHighlightStyle() {
            var cells = document.querySelectorAll(".el-table__body tr.current-row>td.el-table__cell, .el-table__body tr.selection-row>td.el-table__cell");
            for (var i = 0; i < cells.length; i++) {
                cells[i].style = "";
            }
        },
        // 改变高亮行的值, 赋值某一行为高亮行
        changeHighlightStyle(val, row) {
            if (row) {
                var cells = document.querySelectorAll(".el-table__body tr.current-row>td.el-table__cell, .el-table__body tr.selection-row>td.el-table__cell");
                for (var i = 0; i < cells.length; i++) {
                    cells[i].style = "";
                }
                this.$refs._yes_Table.setCurrentRow(row);
            }
            if (val) {
                setTimeout(() => {
                    var cells = document.querySelectorAll(".el-table__body tr.current-row>td.el-table__cell, .el-table__body tr.selection-row>td.el-table__cell");
                    for (var i = 0; i < cells.length; i++) {
                        cells[i].style = val;
                    }
                }, 250);
            }
        },
        handleCellStyle(param, method) {
            if (!method) return;
            const tempFunction = window.Function("param", "vm", "api", "utils", method);
            return tempFunction(param, this, this.formCreateInject.api, utils);
        },
        //拼接插槽里的数据源
        jointFDataSource(column, viewDataSource, index) {
            column.forEach((item) => {
                if (item.fDataSource && !item.fDataSource.startsWith(viewDataSource)) {
                    item.fDataSource = viewDataSource + "." + index + "." + item.dataSource;
                    item.id = item.dataSource + index;
                }
                if (item.modelUid) {
                    item.modelUplUid = viewDataSource + "." + index + "." + item.modelUid;
                }
                let children = item.$children ? item.$children : [];
                if (children.length > 0) {
                    this.jointFDataSource(children, viewDataSource, index);
                }
            });
        },
        //递归遍历加载子列数据源的值
        loadSlotSourceData(column) {
            column.forEach((item) => {
                if (item.fDataSource && item.loadSourceData) {
                    let source = item.fDataSource.split(".");
                    const tempDataSource = this.formCreateInject.api.all().find((item) => item.type === "yes-dataSource" && item.name === source[0]);
                    if (tempDataSource) {
                        item.loadSourceData();
                    }
                }
                if (item.fViewDataSource && item.loadViewSourceData) {
                    let source = item.fDataSource.split(".");
                    const tempViewDataSource = this.formCreateInject.api.all().find((item) => item.type === "yes-dataSource" && item.name === source[0]);
                    if (tempViewDataSource) {
                        item.loadViewSourceData();
                    }
                }
                let children = item.$children ? item.$children : [];
                if (children.length > 0) {
                    this.loadSlotSourceData(children);
                }
            });
        },
        //获取选中的值
        getValue() {
            return this.fvalue;
        },
        // 设置选中的值
        setValue(val) {
            this.defaultCheckedFlag = true;
            if (this.fDataSource) {
                this.updateSourceData(val);
            } else {
                this.setSelectValue(val);
            }
            this.defaultCheckedFlag = false;
        },
        setTableValue(val) {
            this.tableData = val;
            this.orignTableData = val;
        },
        setClickValue(row) {
            this.clickValue = row;
        },
        getClickValue() {
            return this.clickValue;
        },
        setMultiSelect(val) {
            this.multiSelectValue = val;
        },
        loadTable() {
            this.fvalue = "";
            const val = utils.getSourceData(this.fViewDataSource);
            if (val && common.getType(val).indexOf("array") > -1) {
                this.tableData = val;
                this.orignTableData = val;
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        loadSourceData() {
            if (this.selectFlag) {
                //为true则表示由于修改了选中数据源导致的渲染,不执行后续
                this.selectFlag = false;
                return;
            }
            const val2 = utils.getSourceData(this.fDataSource);
            this.setSelectValue(val2);
        },
        //加载内容数据源
        loadViewSourceData() {
            this.load();
            let tBody = this.$children[0].$children.find((item) => item.$vnode.componentOptions.tag === "table-body");
            // //执行插槽加载数据源方法
            tBody.$children.forEach((row) => {
                if (row.index >= 0) {
                    let children = row.$children ? row.$children : [];
                    if (children.length > 0) {
                        this.loadSlotSourceData(children);
                    }
                }
            });
        },
        load() {
            //启用树形表格时,手动加载数据
            if (!this.enableTree) {
                this.loadTable();
                return;
            }
            const val = utils.getSourceData(this.fViewDataSource);
            if (val && common.getType(val).indexOf("array") > -1) {
                this.fvalue = "";
                this.orignTableData = val;
                if (this.lazy) {
                    this.tableData = val;
                    return;
                }
                this.tableData = arrayToTree(val, {
                    parentProperty: this.parentId,
                    customID: this.nodeId
                });
                this.orignTableData = this.tableData;
            }
        },
        // 修改绑定数据源数据
        updateSourceData(val) {
            utils.updateSourceData(this.fDataSource, val ? val : this.fvalue);
        },
        // 设置多选框勾选对象
        setSelectValue(checkedValue) {
            if (!this.showCheckbox) {
                return;
            }
            this.defaultCheckedFlag = true;
            const type = common.getType(checkedValue);
            if (checkedValue) {
                if (type.indexOf("array") > -1 && checkedValue.length >= 0) {
                    if (checkedValue.length === 0) {
                        //长度为0,只做清空操作
                        this.clearSelection();
                        return;
                    }
                    //长度不为0,做更新操作
                    this.clearSelection();
                    let checkedItems = [];

                    checkedValue.forEach((index) => {
                        const indexType = common.getType(index);
                        if (indexType.indexOf("number") > -1 && this.orignTableData.length >= index) {
                            //索引
                            checkedItems.push(this.orignTableData[index]);
                        }
                        if (indexType.indexOf("object") > -1 && this.indexKey) {
                            //对象
                            const row = this.orignTableData.find((item) => item[this.indexKey] === index[this.indexKey]);
                            if (row) {
                                checkedItems.push(row);
                            }
                        }
                    });
                    checkedItems.forEach((item) => {
                        this.toggleRowSelection(item); //勾选
                    });
                    this.fvalue = [...checkedItems];
                    this.selectFlag = true;
                    this.updateSourceData(checkedItems);
                }
            }
            this.defaultCheckedFlag = false;
        },
        getTable() {
            return this.$refs._yes_Table;
        },
        fillData(data) {
            // 数据填充
            this.tableData = data;
        },
        addRow(data) {
            // 添加一行
            this.tableData.push(data);
        },
        updatedSlotData() {
            if (this.$slots.default) {
                this.tableData = this.tableData.length == 0 ? [{}] : this.tableData;
                this.$slots.default.forEach((item) => {
                    if (item.componentOptions) {
                        this.tableData[0][item.componentOptions.propsData.prop] = "11";
                    }
                });
            }
        },
        //全选
        toggleAllSelection() {
            this.$refs._yes_Table.toggleAllSelection();
        },
        //全不选
        clearSelection() {
            this.$refs._yes_Table.clearSelection();
        },
        toggleRowSelection(row) {
            this.$refs._yes_Table.toggleRowSelection(row);
        },
        async rowClick(row, column, event) {
            if (!this.showCheckbox) {
                this.fvalue = [row];
                if (this.fDataSource) {
                    this.updateSourceData([row]);
                }
            }
            this.setClickValue(row);
            if (this.rowClickEvent) {
                const rowClickEvent = window.AsyncFunction("vm", "row", "api", "dao", "fApi", "utils", "column", "event", this.rowClickEvent);
                await rowClickEvent(this, row, this.formCreateInject.api, dao, this.formCreateInject, utils, column, event);
            }
        },
        async rowDblclick(row, column, event) {
            if (!this.showCheckbox) {
                this.fvalue = [row];
                if (this.fDataSource) {
                    this.updateSourceData([row]);
                }
            }
            this.setClickValue(row);
            if (this.rowDblclickEvent) {
                const rowDblclickEvent = window.AsyncFunction("vm", "row", "api", "dao", "fApi", "utils", "column", "event", this.rowDblclickEvent);
                await rowDblclickEvent(this, row, this.formCreateInject.api, dao, this.formCreateInject, utils, column, event);
            }
        },
        async setSelectionIndex(selection) {
            if (!selection) {
                return;
            }
            this.$nextTick(() => {
                // 遍历数组A，根据匹配在数组B中设置已选择状态
                this.$refs._yes_Table.clearSelection();
                selection.forEach((item) => {
                    this.$refs._yes_Table.toggleRowSelection(item, true);
                });
            });
            this.fvalue = selection;
            if (this.fDataSource) {
                this.updateSourceData(this.fvalue);
            }
        },
        async handleSelectionChange(selection, row) {
            // 为true则表示手动给复选框赋值,不执行选中后的方法
            if (this.defaultCheckedFlag) {
                return;
            }
            if (this.multiSelectValue === false) {
                this.$refs._yes_Table.clearSelection();
                selection = [];
                if (row !== undefined) {
                    this.$refs._yes_Table.toggleRowSelection(row);
                    selection.push(row);
                }
            }
            this.selectFlag = true;
            if (this.checkEvent) {
                const rowDblclickEvent = window.AsyncFunction("vm", "selection", "api", "dao", "fApi", "utils", this.checkEvent);
                await rowDblclickEvent(this, selection, this.formCreateInject.api, dao, this.formCreateInject, utils);
            }
            if (JSON.stringify(selection) === JSON.stringify(this.fvalue)) {
                return;
            }
            this.fvalue = selection;
            if (this.fDataSource) {
                this.updateSourceData(this.fvalue);
            }
        },
        loadFun(tree, treeNode, resolve) {
            if (this.loadEvent) {
                const loadEvent = window.AsyncFunction("vm", "tree", "treeNode", "resolve", "api", "dao", "fApi", "utils", this.loadEvent);
                loadEvent(this, tree, treeNode, resolve, this.formCreateInject.api, dao, this.formCreateInject, utils);
            }
        }
    }
};
</script>

<style scoped>
::v-deep .el-table__fixed::before {
    height: 0;
}

::v-deep .el-table__fixed-right::before {
    height: 0;
}

::v-deep .el-table__fixed,
::v-deep .el-table__fixed-right {
    height: auto !important;
    bottom: 17px;
}
</style>
